import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import useWindowSize from 'react-use/lib/useWindowSize'
import Button from 'components/Button'
import ButtonHistoryBack from 'components/ButtonHistoryBack'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Icon from 'components/Icon'
import Image from 'components/Image'
import Layout from 'components/Layout'
import MediaObject from 'components/MediaObject'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import AssessmentResultsForm from 'features/assessments/components/AssessmentResultsForm'
import AssessmentStopwatch from 'features/assessments/components/AssessmentStopwatch'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as assessmentsLib from 'libs/assessments'
import constants from 'styles/constants.module.scss'
import styles from './AssessmentExercise.module.scss'
import countdownAudio from './audio/countdown.mp3'
import timerAudio from './audio/timer.mp3'
import { ReactComponent as ArrowCircleSvg } from './images/arrow---circle.svg'
import { ReactComponent as SmileSvg } from './images/smile.svg'
import { ReactComponent as SparkleSvg } from './images/sparkle.svg'
import { ReactComponent as VolumeFullSvg } from './images/volume---full.svg'
import { ReactComponent as VolumeMuteSvg } from './images/volume---mute.svg'
import feetTogether2xImage from './images/balance/feet-together@2x.jpg'
import flamingo2xImage from './images/balance/flamingo@2x.jpg'
import staggered2xImage from './images/balance/staggered@2x.jpg'
import tandem2xImage from './images/balance/tandem@2x.jpg'

type AssessmentExerciseButtonProps = {
  children: React.ReactNode
  icon: React.ReactElement
}

function AssessmentExerciseButton({ children, icon, ...props }: AssessmentExerciseButtonProps) {
  return (
    <button {...props} type="button" className={styles.button}>
      <Text align="center" color="black" element="div" weight="bold">
        <Row size="xxsmall">
          <Icon color="white">{icon}</Icon>
        </Row>
        <span>{children}</span>
      </Text>
    </button>
  )
}

type AssessmentExerciseTimeProps = {
  children: React.ReactNode
  subtext: string
  title: string
}

function AssessmentExerciseTime({ children, subtext, title }: AssessmentExerciseTimeProps) {
  return (
    <>
      <Row size="medium">
        <Row size="xsmall">
          <Text casing="upper" element="h2" size="xlarge" weight="bold">
            {title}
          </Text>
        </Row>
        <Text element="p" flush size="large">
          {subtext}
        </Text>
      </Row>
      <Row size="small">{children}</Row>
    </>
  )
}

type AssessmentExerciseTimerProps = {
  audioProps?: {
    muted?: boolean
    src: string
  }
  timerProps: {
    children: React.ReactNode
    duration: number
    isPlaying: boolean
    key: number
    onComplete: () => void
  }
} & Partial<AssessmentExerciseTimeProps>

function AssessmentExerciseTimer({
  audioProps,
  timerProps,
  ...props
}: AssessmentExerciseTimerProps) {
  const { width } = useWindowSize()

  return (
    <AssessmentExerciseTime {...props}>
      <div className={styles.timer}>
        <CountdownCircleTimer
          {...timerProps}
          colors={[[constants.PURPLE], [constants.CYAN]]}
          isLinearGradient
          size={width < 568 ? 200 : 380}
          strokeWidth={16}
          trailColor="transparent"
        />
        <audio {...audioProps} autoPlay />
      </div>
    </AssessmentExerciseTime>
  )
}

type AssessmentExerciseTimerNumbersProps = {
  remainingTime: number
}

function AssessmentExerciseTimerNumbers({ remainingTime }: AssessmentExerciseTimerNumbersProps) {
  const currentTime = React.useRef(remainingTime)
  const previousTime = React.useRef(null)
  const isNewTimeFirstTick = React.useRef(false)
  const [, setOneLastRerender] = React.useState(0)

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true
    previousTime.current = currentTime.current
    currentTime.current = remainingTime
  } else {
    isNewTimeFirstTick.current = false
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((value) => value + 1)
    }, 20)
  }

  const isTimeUp = isNewTimeFirstTick.current

  if (remainingTime > 3) return null

  return (
    <div className={styles.countdown}>
      <div
        key={remainingTime}
        className={classNames(
          styles['countdown--number'],
          isTimeUp && styles['countdown--number---up']
        )}>
        {remainingTime}
      </div>
      {previousTime.current !== null && (
        <div
          key={previousTime.current}
          className={classNames(
            styles['countdown--number'],
            !isTimeUp && styles['countdown--number---down']
          )}>
          {previousTime.current}
        </div>
      )}
    </div>
  )
}

type Props = {
  assessmentInternalId: string
}

export default function AssessmentExercise({ assessmentInternalId }: Props) {
  useEventPageVisited('Assessment exercise', { id: assessmentInternalId })
  const globalContext = useGlobalContext()
  const [balanceActivityIndex, setBalanceActivityIndex] = React.useState(0)
  const [isAudioMuted, setIsAudioMuted] = React.useState(false)
  const [isBalanceYesNoDisabled, setIsBalanceYesNoDisabled] = React.useState(true)
  const [showRemindersModal, setShowRemindersModal] = React.useState(true)
  const [showResultsModal, setShowResultsModal] = React.useState(false)
  const [status, setStatus] = React.useState('countdown')
  const [stopwatchValue, setStopwatchValue] = React.useState(0)
  const [timerBalanceKey, setTimerBalanceKey] = React.useState(0)
  const [timerKey, setTimerKey] = React.useState(0)

  const assessment = assessmentsLib.getByInternalId(assessmentInternalId)

  React.useEffect(() => {
    if (status !== 'countdown') return
    setIsBalanceYesNoDisabled(true)
  }, [status])

  React.useEffect(() => {
    if (!stopwatchValue) return
    setShowResultsModal(true)
  }, [stopwatchValue])

  React.useEffect(() => {
    if (!balanceActivityIndex) return

    assessmentsLib.locallyStoreAssessmentInformation(
      assessment.id,
      assessment.title,
      balanceActivityIndex
    )

    if (balanceActivityIndex < 4) return

    setShowResultsModal(true)
  }, [assessment.id, assessment.title, balanceActivityIndex])

  function handleBackButtonClick() {
    globalContext.analytics?.trackEvent(events.EXIT_ASSESSMENT, {
      assessmentId: assessment.id,
      assessmentName: assessment.title,
    })
  }

  function handleBalanceNoClick() {
    assessmentsLib.locallyStoreAssessmentInformation(
      assessment.id,
      assessment.title,
      balanceActivityIndex
    )
    setShowResultsModal(true)
  }

  function handleBalanceYesClick() {
    setStatus('countdown')
    setBalanceActivityIndex(balanceActivityIndex + 1)
    setTimerBalanceKey((prevState) => prevState + 1)
  }

  function handleRemindersConfirm() {
    setShowRemindersModal(false)

    globalContext.analytics?.trackEvent(events.START_ASSESSMENT, {
      assessmentId: assessment.id,
      assessmentName: assessment.title,
    })
  }

  async function handleResultsFormSuccess(goToProgram: boolean) {
    const eventProperties = {
      assessmentId: assessment.id,
      assessmentName: assessment.title,
    }
    await globalContext.analytics?.trackEvent(
      events.COMPLETE_ASSESSMENT,
      eventProperties,
      eventProperties
    )
    navigate(
      _.isBoolean(goToProgram) && goToProgram
        ? paths.PROGRAM_BUILD
        : `${paths.ASSESSMENTS}${assessmentInternalId}/${paths.ASSESSMENT_RESULTS_RELATIVE}`
    )
  }

  function handleStopwatchStop(value: number) {
    setStopwatchValue(value)
  }

  function handleTimerComplete() {
    setShowResultsModal(true)
  }

  function handleTimerMuteClick() {
    setIsAudioMuted(!isAudioMuted)
  }

  function handleTimerRestartClick() {
    setStatus('countdown')
    setTimerKey((prevState) => prevState + 1)
  }

  if (!assessment) return null

  function renderBody() {
    if (assessment.type === assessmentsLib.ASSESSMENT_TYPE_BALANCE) {
      const balanceActivities = [
        {
          title: 'Feet Together',
          subtext: 'Stand with your feet side-by-side.',
          image: feetTogether2xImage,
        },
        {
          title: 'Staggered Stance',
          subtext: 'Place the instep of one foot so it is touching the big toe of the other foot.',
          image: staggered2xImage,
        },
        {
          title: 'Tandem Stance',
          subtext: 'Place one foot in front of the other, heel touching toe.',
          image: tandem2xImage,
        },
        {
          title: 'Flamingo Stance',
          subtext: 'Stand on one foot.',
          image: flamingo2xImage,
        },
      ]

      return (
        <>
          {status === 'countdown' && balanceActivityIndex < 4 && (
            <AssessmentExerciseTimer
              title="Get ready!"
              subtext={balanceActivities[balanceActivityIndex].subtext}
              timerProps={{
                children: <Image src={balanceActivities[balanceActivityIndex].image} circle />,
                duration: 12,
                isPlaying: !showRemindersModal,
                key: timerKey,
                onComplete: () => setStatus('timer'),
              }}
            />
          )}
          {assessment.type === 'balance' && status === 'timer' && balanceActivityIndex < 4 && (
            <Row>
              <AssessmentExerciseTimer
                title={balanceActivities[balanceActivityIndex].title}
                subtext="Try to hold for 10 seconds."
                timerProps={{
                  children: <Image src={balanceActivities[balanceActivityIndex].image} circle />,
                  duration: 10,
                  isPlaying: true,
                  key: timerBalanceKey,
                  onComplete: () => setIsBalanceYesNoDisabled(false),
                }}
              />
              <Text align="center" element="p">
                Were you able to balance for 10 seconds without holding a chair?
              </Text>
              <Buttons align="center">
                <Button
                  color="white"
                  disabled={isBalanceYesNoDisabled}
                  onClick={handleBalanceYesClick}>
                  Yes
                </Button>
                <Text casing="upper" weight="bold">
                  or
                </Text>
                <Button
                  color="white"
                  disabled={isBalanceYesNoDisabled}
                  onClick={handleBalanceNoClick}>
                  No
                </Button>
              </Buttons>
            </Row>
          )}
        </>
      )
    }

    return (
      <>
        {status === 'countdown' && (
          <AssessmentExerciseTimer
            title="Countdown"
            subtext="Get ready!"
            audioProps={{
              src: showRemindersModal ? null : countdownAudio,
              muted: isAudioMuted,
            }}
            timerProps={{
              children: AssessmentExerciseTimerNumbers,
              duration: 10,
              isPlaying: !showRemindersModal,
              key: timerKey,
              onComplete: () => setStatus('timer'),
            }}
          />
        )}
        {assessment.type === assessmentsLib.ASSESSMENT_TYPE_REPS && status === 'timer' && (
          <AssessmentExerciseTimer
            title="Timer"
            subtext="Please keep track of your reps!"
            audioProps={{
              src: timerAudio,
              muted: isAudioMuted,
            }}
            timerProps={{
              duration: 30,
              isPlaying: true,
              key: timerKey,
              onComplete: handleTimerComplete,
            }}
          />
        )}
        {assessment.type === assessmentsLib.ASSESSMENT_TYPE_TIMED && status === 'timer' && (
          <AssessmentExerciseTime
            title="Timer"
            subtext="Press ‘Stop’ or the space bar when you finish!">
            <AssessmentStopwatch
              audioProps={{
                muted: isAudioMuted,
              }}
              onStop={handleStopwatchStop}
            />
          </AssessmentExerciseTime>
        )}
      </>
    )
  }

  //const isBalanceTimer = assessment.type === assessmentsLib.ASSESSMENT_TYPE_BALANCE && status === 'timer'
  const isBalanceTimer = assessment.type === assessmentsLib.ASSESSMENT_TYPE_BALANCE

  return (
    <Layout footer={false} className={classNames('AssessmentExercise', styles.this)}>
      <View color="grey" className={styles.view}>
        <Row size="small">
          <ButtonHistoryBack color="white" icon="arrow-left" onClick={handleBackButtonClick} />
        </Row>
        <Text align="center" element="div">
          <Container flush size="medium">
            {renderBody()}
            <Buttons align={isBalanceTimer ? 'center' : undefined} split={!isBalanceTimer}>
              {!isBalanceTimer && (
                <AssessmentExerciseButton
                  icon={
                    isAudioMuted ? <VolumeFullSvg aria-hidden /> : <VolumeMuteSvg aria-hidden />
                  }
                  onClick={handleTimerMuteClick}>
                  {isAudioMuted ? 'Unmute' : 'Mute'}
                </AssessmentExerciseButton>
              )}
              <AssessmentExerciseButton icon={<ArrowCircleSvg />} onClick={handleTimerRestartClick}>
                Restart
              </AssessmentExerciseButton>
            </Buttons>
          </Container>
        </Text>
      </View>
      <Modal hideClose isOpen={showRemindersModal}>
        <ModalBox
          title="Reminders for completing the assessment"
          icon={<SparkleSvg />}
          subtext={assessment.reminders.intro}>
          <Row size="small">
            <Text align="left" element="div">
              {assessment.reminders.items.map((item) => (
                <Row key={item.copy} size="xsmall">
                  <div className={styles['reminders--item']}>
                    <MediaObject
                      center
                      figure={
                        <Icon>
                          <item.icon />
                        </Icon>
                      }
                      size="small">
                      <Text element="p" flush>
                        {item.copy}
                      </Text>
                    </MediaObject>
                  </div>
                </Row>
              ))}
            </Text>
          </Row>
          <Button color="yellow" full size="xlarge" onClick={handleRemindersConfirm}>
            I’m ready
          </Button>
        </ModalBox>
      </Modal>
      <Modal hideClose isOpen={showResultsModal}>
        <ModalBox
          title="You did great!"
          icon={<SmileSvg />}
          subtext="This will conclude your assessment and help build out your Bold profile.">
          <AssessmentResultsForm
            assessment={assessment}
            timeValue={stopwatchValue}
            onSuccess={handleResultsFormSuccess}
          />
        </ModalBox>
      </Modal>
    </Layout>
  )
}
