import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Divider from 'components/Divider'
import Head from 'components/Head'
import Hero from 'components/Hero'
import Image from 'components/Image'
import Layout from 'components/Layout'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as assessments from 'libs/assessments'
import styles from './Assessments.module.scss'
import SignUpBox from './components/SignUpBox'
import heroSmallImage from './images/hero---small.png'
import heroSmall2xImage from './images/hero---small@2x.png'
import heroImage from './images/hero.png'
import hero2xImage from './images/hero@2x.png'

type AssessmentsSmallProps = {
  children: string
}

function AssessmentsSmall({ children }: AssessmentsSmallProps) {
  return (
    <Text casing="upper" color="white" element="h2" weight="bold">
      <span className={styles.small}>{children}</span>
    </Text>
  )
}

export default function Assessments() {
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    if (!globalContext.isAuthed) return
    navigate(paths.ASSESSMENTS_AUTHED)
  }, [globalContext.isAuthed])

  React.useEffect(() => {
    globalContext.updateEventData({
      [events.SIGN_UP_FROM_KEY]: 'assessments',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const divider = <Divider axis="y" />

  return (
    <Layout className={classNames('Assessments', styles.this)}>
      <Head
        title="How’s my health?"
        description="Assess your strength, mobility, and balance with these simple 1-minute tests"
      />
      <Hero
        small="Assessments"
        title="How’s my health?"
        copy={
          <>
            Assess your strength, mobility, and balance with these simple{' '}
            <Text highlight weight="bold">
              1-minute
            </Text>{' '}
            tests.
          </>
        }
        image={heroImage}
        image2x={hero2xImage}
        imageSmall={heroSmallImage}
        imageSmall2x={heroSmall2xImage}
      />
      <Row size="small">
        <Container size="xxlarge">
          <Container align="left" flush size="medium">
            <Row size="xxxlarge">
              <Row size="small">
                <Row size="small">
                  <AssessmentsSmall>About assessments</AssessmentsSmall>
                </Row>
                <Text element="h3" size="xlarge" weight="bold">
                  Discover your current fitness level with Bold’s simple and easy to do assessments.
                </Text>
              </Row>
              <Text element="p" flush size="xlarge">
                You’ll be guided through each test in a quick video with Bold’s expert trainers.
                After you complete the assessment, you can save your score and create a Bold
                profile.
              </Text>
            </Row>
            <Row size="small">
              <AssessmentsSmall>Get started</AssessmentsSmall>
            </Row>
            <Text size="xxlarge">Your journey starts here</Text>
          </Container>
        </Container>
      </Row>
      <Container size="xxlarge" className={styles['assessments-container']}>
        {assessments.ITEMS.map((assessment, index) => (
          <React.Fragment key={assessment.internalId}>
            <div
              className={classNames(
                styles['assessments--item'],
                index % 2 !== 0 && styles['assessments--item---alt']
              )}>
              <div className={styles['assessments--item--photo-box']}>
                <Row size="small" className={styles['assessments--item--title']}>
                  <Text casing="upper" color="white" element="h3" size="large" weight="bold">
                    {assessment.title} assessment
                  </Text>
                </Row>
                <div className={styles['assessments--item--photo-wrap']}>
                  <Image
                    src={assessment.photo}
                    src2x={assessment.photo2x}
                    className={styles['assessments--item--photo']}
                  />
                </div>
              </div>
              <Text color="white" element="div" className={styles['assessments--item--box']}>
                <div className={styles['assessments--item--box--body']}>
                  <Row>
                    <MediaObject
                      center
                      figure={
                        <Text
                          color="blue"
                          element="div"
                          className={styles['assessments--item--icon']}>
                          <assessment.icon />
                        </Text>
                      }
                      size="small">
                      <Text copy>
                        <Text element="div" casing="upper" weight="bold">
                          {assessment.title}
                        </Text>
                        <Text element="p" flush>
                          {assessment.description}
                        </Text>
                      </Text>
                    </MediaObject>
                  </Row>
                  <Row size="xsmall">
                    <Text element="p" flush size="xlarge">
                      To do this test, you’ll need:
                    </Text>
                  </Row>
                  {assessment.accessories &&
                    assessment.accessories.map((item, itemIndex) => (
                      <Row
                        flush={itemIndex === _.size(assessment.accessories)}
                        key={item.copy}
                        size="xsmall">
                        <MediaObject
                          center
                          figure={
                            <item.icon className={styles['assessments--item--accessories--icon']} />
                          }
                          size="small">
                          <Text copy>{item.copy}</Text>
                        </MediaObject>
                      </Row>
                    ))}
                </div>
                <footer className={styles['assessments--item--box--footer']}>
                  <Button
                    to={`${paths.ASSESSMENTS}${assessment.internalId}/`}
                    color="yellow"
                    full
                    size="xlarge"
                    className={styles['assessments--item--box--button']}>
                    Start assessment
                  </Button>
                </footer>
              </Text>
            </div>
            {divider}
          </React.Fragment>
        ))}
        <Container size="xlarge">
          <SignUpBox className={styles['sign-up']} />
        </Container>
      </Container>
    </Layout>
  )
}
